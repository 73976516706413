var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Enabling of seconds spinbutton" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeSecond) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("To enable the section of seconds,set the ")]),
        _c("code", [_vm._v("show-seconds")]),
        _c("span", [_vm._v(" prop to ")]),
        _c("code", [_vm._v("true")]),
        _c("span", [_vm._v(" to enable the seconds selection spinbutton.")])
      ]),
      _c("b-form-timepicker", {
        attrs: { "show-seconds": "", locale: "en" },
        model: {
          value: _vm.value,
          callback: function($$v) {
            _vm.value = $$v
          },
          expression: "value"
        }
      }),
      _c("b-card-text", { staticClass: "mt-2 mb-0" }, [
        _vm._v(" Value: '" + _vm._s(_vm.value) + "' ")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }