var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Control sizing" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeSize) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("Fancy a smaller or larger ")]),
        _c("code", [_vm._v("<b-form-timepicker>")]),
        _c("span", [_vm._v(" control? Set the ")]),
        _c("code", [_vm._v("size")]),
        _c("span", [_vm._v(" prop to ")]),
        _c("code", [_vm._v("'sm'")]),
        _c("span", [_vm._v(" for a smaller form control, or ")]),
        _c("code", [_vm._v("'lg'")]),
        _c("span", [
          _vm._v(
            " for a larger form form control. Note this does not affect the size of the popup time selection dialog. "
          )
        ])
      ]),
      _c("label", { attrs: { for: "timepicker-sm" } }, [
        _vm._v("Small time picker")
      ]),
      _c("b-form-timepicker", {
        staticClass: "mb-2",
        attrs: { id: "timepicker-sm", size: "sm", local: "en" }
      }),
      _c("label", { attrs: { for: "timepicker-lg" } }, [
        _vm._v("Large time picker")
      ]),
      _c("b-form-timepicker", {
        attrs: { id: "timepicker-lg", size: "lg", local: "en" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }