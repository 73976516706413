var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Disabled and readonly states" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeState) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("Setting the ")]),
        _c("code", [_vm._v("disabled")]),
        _c("span", [_vm._v(" prop will remove all interactivity of the ")]),
        _c("code", [_vm._v("<b-form-timepicker>")]),
        _c("span", [_vm._v(" component.")])
      ]),
      _c("b-card-text", [
        _c("span", [_vm._v("Setting the ")]),
        _c("code", [_vm._v("readonly")]),
        _c("span", [
          _vm._v(
            " prop will disable selecting a time, but will keep the component interactive, allowing for tabbing between spinbuttons. The "
          )
        ]),
        _c("code", [_vm._v("v-model")]),
        _c("span", [_vm._v(" will not be updated in the readonly state.")])
      ]),
      _c(
        "div",
        [
          _c(
            "b-form-group",
            { attrs: { label: "Select time picker interactive state" } },
            [
              _c(
                "b-form-radio-group",
                {
                  attrs: { "aria-controls": "ex-disabled-readonly" },
                  model: {
                    value: _vm.state,
                    callback: function($$v) {
                      _vm.state = $$v
                    },
                    expression: "state"
                  }
                },
                [
                  _c("b-form-radio", { attrs: { value: "disabled" } }, [
                    _vm._v(" Disabled ")
                  ]),
                  _c("b-form-radio", { attrs: { value: "readonly" } }, [
                    _vm._v(" Readonly ")
                  ]),
                  _c("b-form-radio", { attrs: { value: "normal" } }, [
                    _vm._v(" Normal ")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("b-form-timepicker", {
            attrs: {
              id: "ex-disabled-readonly",
              disabled: _vm.disabled,
              readonly: _vm.readonly
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }