var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Button only mode" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeButtonOnly) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [
          _vm._v(
            " Fancy just a button that launches the timepicker dialog, or want to provide your own optional text input field? Use the "
          )
        ]),
        _c("code", [_vm._v("button-only")]),
        _c("span", [
          _vm._v(
            " prop to render the timepicker as a dropdown button. The formatted time label will be rendered with the class "
          )
        ]),
        _c("code", [_vm._v("sr-only")]),
        _c("span", [_vm._v(" (available only to screen readers).")])
      ]),
      _c(
        "div",
        [
          _c("label", { attrs: { for: "example-input" } }, [
            _vm._v("Choose a time")
          ]),
          _c(
            "b-input-group",
            { staticClass: "mb-1" },
            [
              _c("b-form-input", {
                attrs: {
                  id: "example-input",
                  type: "text",
                  placeholder: "HH:mm:ss"
                },
                model: {
                  value: _vm.value,
                  callback: function($$v) {
                    _vm.value = $$v
                  },
                  expression: "value"
                }
              }),
              _c(
                "b-input-group-append",
                [
                  _c("b-form-timepicker", {
                    attrs: {
                      "button-only": "",
                      "button-variant": "outline-primary",
                      right: "",
                      size: "sm",
                      "show-seconds": "",
                      locale: "en",
                      "aria-controls": "example-input"
                    },
                    model: {
                      value: _vm.value,
                      callback: function($$v) {
                        _vm.value = $$v
                      },
                      expression: "value"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("b-card-text", [_vm._v("Value: '" + _vm._s(_vm.value) + "'")])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }