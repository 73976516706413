var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Internationalization" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeInternationalization) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("By default ")]),
        _c("code", [_vm._v("<b-form-timepicker>")]),
        _c("span", [
          _vm._v(
            " will use the browser's default locale, but you can specify the locale (or locales) to use via the "
          )
        ]),
        _c("code", [_vm._v("locale")]),
        _c("span", [
          _vm._v(
            " prop. The prop accepts either a single locale string, or an array of locale strings (listed in order of most preferred locale to least prefered). "
          )
        ])
      ]),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "mb-3", attrs: { cols: "12" } },
            [
              _c("label", { attrs: { for: "example-locales" } }, [
                _vm._v("Locale:")
              ]),
              _c("b-form-select", {
                attrs: { id: "example-locales", options: _vm.locales },
                model: {
                  value: _vm.locale,
                  callback: function($$v) {
                    _vm.locale = $$v
                  },
                  expression: "locale"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c(
                "b-form-timepicker",
                _vm._b(
                  {
                    attrs: { locale: _vm.locale, "show-seconds": "" },
                    on: { context: _vm.onContext },
                    model: {
                      value: _vm.value,
                      callback: function($$v) {
                        _vm.value = $$v
                      },
                      expression: "value"
                    }
                  },
                  "b-form-timepicker",
                  _vm.labels[_vm.locale] || {},
                  false
                )
              )
            ],
            1
          ),
          _c(
            "b-col",
            [
              _c("b-card-text", [
                _vm._v(" Value: "),
                _c("b", [_vm._v("'" + _vm._s(_vm.value) + "'")])
              ]),
              _c("b-card-text", { staticClass: "mb-0" }, [
                _vm._v(" Context: ")
              ]),
              _c("pre", { staticClass: "small" }, [_vm._v(_vm._s(_vm.context))])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }